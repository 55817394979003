// Get height of document
function getDocHeight() {
    var content = document.getElementById('rent-form');
    var height = Math.max( content.scrollHeight, content.offsetHeight);
    return height;
}

// send docHeight onload
function sendDocHeightMsg(e) {
    var ht = getDocHeight();
    parent.postMessage( JSON.stringify( {'reservationFormHeight': ht} ), '*' );
}

// assign onload handler 
if ( window.addEventListener ) {
    window.addEventListener('load', function(e){
        setInterval(function(){
            sendDocHeightMsg(e)
        }, 500);
    }, false);
}